import Vue from 'vue';
import store from '@/store/index';
import { PAYPAL_CLIENT_TOKEN } from '../store/actions/paypal';
import config from '@/config/config';

const paypalInstance = () => {
  return new Vue({
    data() {
      return {
        processPayment: false,
        stepPayment: 'process',
        isAuthorize: false,
        isComplete: false,
        invoice: undefined,
        isDisablePaypalButton: true,
        renderPaypalCreditCard: false,
        onSubmitPaypal: false,
      };
    },
    async created() {
      const script = document.getElementById('paypal-script');
      // Check if script already exists
      if (script) return null;

      // Get client token
      const response = await store.dispatch(PAYPAL_CLIENT_TOKEN);
      if (response.data && response.data.client_token) {
        const script = document.createElement('script');
        script.setAttribute(
          'src',
          'https://www.paypal.com/sdk/js?components=buttons,hosted-fields&client-id=AQZ34RcrB6GmShbVCnrwvfjoUYOdkr1gcMLh181vJ5JNyhPaNydylrSTRgP1vvIh4Ybz7YzZ4lHNHIrb&disable-funding=credit',
        );
        script.setAttribute('data-client-token', response.data.client_token);
        document.head.appendChild(script);
      }
    },

    methods: {
      reset() {
        this.processPayment = false;
        this.stepPayment = 'process';
        this.isAuthorize = false;
        this.isComplete = false;
      },
      resetLoading() {
        this.processPayment = false;
      },
      isLoading() {
        this.processPayment = true;
      },
      async creditCardRender(
        total = 0,
        discount = 0,
        cart = [],
        coupon = undefined,
        creditCard = undefined,
        options = {
          onError: () => {},
          onCompleted: () => {},
          onCreateOrder: () => {},
        },
      ) {
        const container = document.getElementById(
          'paypal-credit-card-button-container',
        );
        console.log('DEBUG:PAYPAL-CREDIT',container);
        const vm = this;
        if (!container) return;
        // eslint-disable-next-line
        if (paypal && paypal.HostedFields.isEligible()) {
          let orderId;

          const items = cart.map((item) => ({
            name: item.name,
            unit_amount: {
              currency_code: 'USD',
              value: item.price,
            },
            quantity: 1,
          }));

          const amount = {
            value: total,
            currency_code: 'USD',
            breakdown: {
              item_total: {
                currency_code: 'USD',
                value: total,
              },
            },
          };

          if (discount) {
            amount.value = total - discount;
            amount.breakdown = {
              ...amount.breakdown,
              discount: {
                currency_code: 'USD',
                value: discount,
              },
            };
          }

          // eslint-disable-next-line
          paypal.HostedFields.render({
            // Call your server to set up the transaction
            createOrder: () => {
              return fetch(config.data.apiUrl + '/user/payment/create-order', {
                method: 'post',
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                  // eslint-disable-next-line no-undef
                  Authorization: 'Bearer ' + localStorage.getItem('user-token'),
                },
                body: JSON.stringify({
                  purchase_units: [
                    {
                      amount,
                      items,
                    },
                  ],
                }),
                // use the "body" param to optionally pass additional order information like
                // product ids or amount.
              })
                .then((res) => res.json())
                .then((orderData) => {
                  orderId = orderData.id; // needed later to complete capture
                  return orderData.id;
                });
            },
            styles: {
              '.valid': {
                color: 'green',
              },
              '.invalid': {
                color: 'red',
              },
            },
            fields: {
              number: {
                selector: '#card-number',
                placeholder: '4111 1111 1111 1111',
                prefill: creditCard.number,
              },
              cvv: {
                selector: '#cvv',
                placeholder: '123',
                prefill: creditCard.cvc,
              },
              expirationDate: {
                selector: '#expiredDate',
                placeholder: 'MM/YY',
                prefill: creditCard.expired_date,
              },
            },
          }).then((cardFields) => {
            container.addEventListener('click', (event) => {
              vm.isLoading();

              event.preventDefault();
              cardFields
                .submit({
                  cardholderName: document.getElementById('cardholderName')
                    ? document.getElementById('cardholderName').value
                    : '',
                  billingAddress: {
                    streetAddress: creditCard.address,
                    region: creditCard.state,
                    locality: creditCard.city,
                    postalCode: creditCard.postal_code,
                    countryCodeAlpha2: creditCard.country.code,
                  },
                })
                .then(() => {
                  vm.isAuthorize = true;
                  vm.stepPayment = 'authorize';

                  fetch(
                    config.data.apiUrl +
                      `/user/payment/capture-order/${orderId}`,
                    {
                      headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        // eslint-disable-next-line no-undef
                        Authorization:
                          'Bearer ' + localStorage.getItem('user-token'),
                      },
                      body: JSON.stringify({
                        cart,
                        coupon,
                      }),
                      method: 'post',
                    },
                  )
                    .then((res) => res.json())
                    .then((orderData) => {
                      const errorDetail =
                        Array.isArray(orderData.details) &&
                        orderData.details[0];
                      if (errorDetail) {
                        if (errorDetail.description) {
                          vm.resetLoading();
                          options.onError(errorDetail.description);
                        }

                        if (orderData.debug_id)
                          console.log('debug_id', orderData.debug_id);
                      }

                      vm.isComplete = true;
                      vm.stepPayment = 'completed';
                      return options.onCompleted(orderData);
                    });
                })
                .catch((err) => {
                  vm.resetLoading();
                  options.onError(err.message);
                });
            });
            this.onSubmitPaypal = false;
            container.click();
          });
        }

        this.renderPaypalCreditCard = true;
      },
      async render(
        total = 0,
        discount = 0,
        cart = [],
        coupon = undefined,
        options = {
          onError: () => {},
          onCompleted: () => {},
        },
      ) {
        const container = document.getElementById('paypal-button-container');
        const vm = this;
        if (!container) return;

        const items = cart.map((item) => ({
          name: item.name,
          unit_amount: {
            currency_code: 'USD',
            value: item.price,
          },
          quantity: 1,
        }));

        const amount = {
          value: total,
          currency_code: 'USD',
          breakdown: {
            item_total: {
              currency_code: 'USD',
              value: total,
            },
          },
        };

        if (discount) {
          amount.value = total - discount;
          amount.breakdown = {
            ...amount.breakdown,
            discount: {
              currency_code: 'USD',
              value: discount,
            },
          };
        }
        // eslint-disable-next-line
        paypal
          .Buttons({
            style: {
              label: 'checkout',
              maxWidth: '100%',
            },
            createOrder: function (data, actions) {
              return actions.order.create({
                application_context: {
                  shipping_preference: 'NO_SHIPPING',
                },
                purchase_units: [
                  {
                    amount,
                    items,
                  },
                ],
              });
            },

            // Finalize the transaction after payer approval
            onApprove: function (data) {
              vm.isLoading();
              fetch(
                config.data.apiUrl + `/user/payment/process/${data.orderID}`,
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    // eslint-disable-next-line no-undef
                    Authorization:
                      'Bearer ' + localStorage.getItem('user-token'),
                  },
                  method: 'get',
                },
              )
                .then((resp) => resp.json())
                .then((data) => {
                  vm.isAuthorize = true;
                  vm.stepPayment = 'authorize';
                  fetch(
                    config.data.apiUrl + `/user/payment/authorize/${data.id}`,
                    {
                      headers: {
                        'Content-Type': 'application/json',
                        accept: 'application/json',
                        // eslint-disable-next-line no-undef
                        Authorization:
                          'Bearer ' + localStorage.getItem('user-token'),
                      },
                      body: JSON.stringify({
                        cart,
                        coupon,
                      }),
                      method: 'post',
                    },
                  )
                    .then((resp) => resp.json())
                    .then((resp) => {
                      vm.isComplete = true;
                      vm.stepPayment = 'completed';
                      options.onCompleted(resp);
                    })
                    .catch((err) => {
                      vm.resetLoading();
                      options.onError(err.message);
                    });
                })
                .catch((err) => {
                  vm.resetLoading();
                  options.onError(err.message);
                });
            },
            onCancel: function (data) {
              vm.processPayment = false;
              options.onError(data);
              console.log(data);
            },
            onError: (err, actions) => {
              console.log('err', err);
              return actions ? actions.restart() : null;
            },
          })
          .render(`#paypal-button-container`);
      },
    },
  });
};

export const PaypalPlugin = {
  install(Vue) {
    Vue.prototype.$paypal = paypalInstance();
  },
};
